.Error {
  @include fc;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;

  .home-link {
    position: absolute;
    top: 3rem;
    right: 5rem;
    & a img {
      width: 10rem;
    }
  }

  &_container {
    transform: translateY(-10%);
    border: 1px solid $tertiary-gray;
    border-radius: 3.5rem;
    padding: 6rem 5rem 3rem 5rem;
    max-width: 50rem;

    &_heading {
      font-size: 2.5rem;
      font-weight: 400;
      color: $secondary-blue;
      margin: 0;
      margin-bottom: 3rem;
    }

    &_message {
      font-size: 1.6rem;
      font-weight: 400;
      color: $primary-gray;
      line-height: 1.4;
      margin-bottom: 4rem;
    }

    &_buttons {
      @include fc(1, 0);
      gap: 2rem;
      & > a img {
        width: 19rem;
      }
    }
  }
}
