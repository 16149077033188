// todo: redo this
.App-Input {
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    font-weight: 500;
    color: $tertiary-black;
    margin-bottom: 10px;
  }

  &_combo-box {
    .search {
      .App-Input input {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        background-color: white;
        z-index: 4;
      }
    }

    .combo-box_separator {
      height: 1px;
      margin: 0.5rem 0;
      background-color: $tertiary-gray;
    }
  }

  &_input-container {
    @include fc(1, 0);

    .left-default {
      background-color: $tertiary-white;
      border: 1px solid $quaternary-gray;
      padding: 1.5rem;
      font-size: 1.6rem;
      border-radius: 0.8rem;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      line-height: 1.7rem;
      color: $quinary-gray;
    }
    input {
      border: 1px solid $quaternary-gray;
      border-radius: 0.8rem;
      padding: 1.5rem;
      width: 100%;
      font-size: 1.6rem;
      line-height: 1.7rem;
      color: $primary-black;

      &::placeholder {
        color: $tertiary-gray;
      }
      &:focus {
        outline: none;
        // border: 1px solid $primary-blue;
      }

      &.left-present {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &_error {
    font-family: $secondary-font;
    color: $primary-red;
    font-size: 1.2rem;
    margin-top: 10px;
    font-weight: 500;
    position: absolute;
    bottom: -20px;
  }
}

.combo-box_viewport {
  height: 50rem !important;
  min-width: 40rem;
  font-size: 1.6rem;
  color: $primary-black;
  font-weight: 400;
  background: $primary-white;
  border-radius: 1rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  padding: 1rem;

  .no-results {
    font-size: 1.6rem;
    color: $primary-gray;
    font-weight: 500;
    text-align: center;
    padding: 1rem;
  }
}

.combo-box_group {
  cursor: pointer;
  &_item {
    padding: 0.5rem !important;

    .flag-icon {
      margin-right: 1rem;
    }
    .country {
      margin-right: 1rem;
      color: $primary-black;
    }
    .dial-code {
      color: $quinary-gray;
    }

    &[data-disabled] {
      pointer-events: none;
    }

    &[data-highlighted] {
      font-weight: 600;
      outline: none;
    }
  }
}

@import "./RadioChipInput";
