.Header {
  @include fc(1, 0);
  justify-content: space-between;
  padding-left: 4rem;
  padding-right: 6rem;
  border-bottom: 1px solid $border-gray;

  &_left .searchbox {
    .App-Input {
      min-width: 32rem;

      input {
        background: $tertiary-blue;
        height: 4rem;
        border: 1px solid $border-gray;
        font-size: 1.4rem;
      }
    }
  }

  &_right {
    @include fc(1, 0);

    .portfolio-value {
      @include fc(0, 1);
      flex-direction: column;
      text-transform: uppercase;
      text-align: right;
      margin-right: 2.5rem;

      .text {
        font-weight: 600;
        font-size: 1.5rem;
        letter-spacing: 1px;
        margin-bottom: 0.75rem;
      }
      .value {
        font-size: 1.8rem;
        opacity: 0.8;
      }
    }

    .avatar {
      border-radius: 50%;
      width: 7rem;
      margin-right: 1rem;
    }

    .dropdown {
      cursor: pointer;

      &-toggle svg {
        width: 1.8rem;
        color: $primary-blue;
      }

      &-content {
        width: 15rem;
        display: flex;
        flex-direction: column;
        border-radius: 0.5rem;
        padding: 1rem;
        background-color: $primary-white;
        border: 1px solid $secondary-gray;

        .SidebarItem {
          color: $primary-black;
          font-family: $secondary-font;
          font-size: 1.8rem;
          font-weight: 500;
          cursor: pointer;

          .text {
            line-height: 1.8rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
