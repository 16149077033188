/**
ALL THE DEFAULT SETTINGS FOR THE APP, NO REAL STYLING SHOULD BE APPLIED HERE
**/

// #### IMPORTS ####
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

// #### FONTS ####

// Whitney font
@font-face {
  font-family: Whitney;
  src: url("../../assets/fonts/whitney/whitneybook.otf");
  font-weight: normal;
}
@font-face {
  font-family: Whitney;
  src: url("../../assets/fonts/whitney/whitneymedium.otf");
  font-weight: 500;
}
@font-face {
  font-family: Whitney;
  src: url("../../assets/fonts/whitney/whitneysemibold.otf");
  font-weight: 600;
}
@font-face {
  font-family: Whitney;
  src: url("../../assets/fonts/whitney/whitneybold.otf");
  font-weight: 700;
}

// AvenirBook font
@font-face {
  font-family: AvenirBook;
  src: url("../../assets/fonts/avenirBook/AvenirLTStd-Book.otf");
  font-weight: normal;
}
@font-face {
  font-family: AvenirBook;
  src: url("../../assets/fonts/avenirBook/Avenir Heavy.ttf");
  font-weight: 600;
}
@font-face {
  font-family: AvenirBook;
  src: url("../../assets/fonts/avenirBook/AvenirLTStd-Black.otf");
  font-weight: bold;
}

// #### SCREEN SIZES ####
$mobile: 330px;
$tablet: 495px;
$desktop: 872px;
$big-desktop: 1128px;

// #### MEDIA-QUERIES ####

// mobile
$mobile-down-query: "only screen and (max-width : #{$mobile})";
$mobile-up-query: "only screen and (min-width : #{$mobile})";

// tablet
$tablet-down-query: "only screen and (max-width : #{$tablet})";
$tablet-up-query: "only screen and (min-width : #{$tablet})";

// desktop
$desktop-down-query: "only screen and (max-width : #{$desktop})";
$desktop-up-query: "only screen and (min-width : #{$desktop})";

// big-desktop
$big-desktop-up-query: "only screen and (min-width : #{$big-desktop})";

@mixin up-query($width) {
  @media only screen and (min-width: #{$width}) {
    @content;
  }
}

@mixin down-query($width) {
  @media only screen and (max-width: #{$width}) {
    @content;
  }
}

@mixin xdq($query) {
  @media #{$query} {
    @content;
  }
}

@mixin mdq {
  @media #{$mobile-down-query} {
    @content;
  }
}

@mixin tdq {
  @media #{$tablet-down-query} {
    @content;
  }
}

@mixin ddq {
  @media #{$desktop-down-query} {
    @content;
  }
}

@mixin muq {
  @media #{$mobile-up-query} {
    @content;
  }
}

@mixin tuq {
  @media #{$tablet-up-query} {
    @content;
  }
}

@mixin duq {
  @media #{$desktop-up-query} {
    @content;
  }
}

// #### COLORS ####
$primary-blue: #8ed8f8;
$secondary-blue: #00aeef;
$tertiary-blue: #fcfafd;

$primary-black: #292526;
$secondary-black: #242e40;
$tertiary-black: #374258;

$primary-gray: #908f92;
$secondary-gray: #e6e7e8; //btn
$tertiary-gray: #98a4ba;
//error border color #d3d5d6
$quaternary-gray: #eaeef5;
$quinary-gray: #8b97b1;

$text-black: #231f20;
$text-blue: #00aeef;
$news-blue: #184273;
$bg-blue: #8dd7f7;
$border-gray: #d1d2d4;

$primary-white: #fefffe;
$tertiary-white: #f9f9fd;

$primary-red: #e84450;
$secondary-red: #f8abad;
$tertiary-red: rgb(255, 26, 104);

$primary-green: #9ad2ae;
$secondary-green: rgb(75, 192, 192);

// #### MISC VARIABLES ####
$default-space: 1.5rem;
$font-size: 10px;
$primary-font: "AvenirBook", sans-serif;
$secondary-font: "Whitney", sans-serif;
$tertiary-font: "Montserrat", sans-serif;

// #### TOASTIFY OVERRIDES ####

.Toastify {
  &__toast {
    font: 400 14px $primary-font;
    height: max-content;
    min-height: 50px;

    &-container {
      width: max-content;
    }
    &-body {
      padding-right: 2rem;
    }
    &--error {
      background: #f2dede;
      color: #d50505;

      .Toastify__progress-bar {
        background: #c50505;
      }
    }
    &--info {
      background: #e8f7fe;
      color: #0090fa;

      .Toastify__progress-bar {
        background: #0983da;
      }
    }
    &--success {
      background: #cdffe7;
      color: #1caf67;

      .Toastify__progress-bar {
        background: #1fc776;
      }
    }
  }

  &__close-button {
    color: #00000080;
    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }

  &__progress-bar {
    background: rgba(85, 85, 85, 0.84);
  }
}
