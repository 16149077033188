/**
0. MIXINS AND HELPER STYLES (functions?) 
1. BOILERPLATE STYLING FOR ELEMENTS, ALL THE DEFAULT STYLING SHOULD BE USED HERE
**/

// #### MIXINS ####
@mixin no-gutter($lh: 0) {
  margin: 0;
  padding: 0;
  line-height: $lh;
}

// todo:? give parameter(s)
@mixin text-ellipsis {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}

@mixin flex-centered($a: 1, $j: 1) {
  display: flex;
  @if ($a==1) {
    align-items: center;
  }
  @if ($j==1) {
    justify-content: center;
  }
}

@mixin fit-img {
  & > img {
    object-fit: cover;
    width: 100%;
    max-height: 100%;
  }
}

// ALIASES
@mixin t-e {
  @include text-ellipsis();
}

@mixin fc($a: 1, $j: 1) {
  @include flex-centered($a, $j);
}

@mixin f-i {
  @include fit-img;
}

// #### STYLES TO ELEMENTS ####
@import url("https://unpkg.com/@aman-atg/normal.css");

html {
  box-sizing: border-box;
  font-size: $font-size;
  font-family: $primary-font;
}

body {
  font-size: inherit;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  background: white;
  border: none;
}

h4 {
  display: inline;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

// #### HELPER CLASSES ####

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
  &:focus {
    outline: none;
  }
}

.btn {
  padding: 1.5rem 2rem;
  border-radius: 1.2rem;
  font-size: 1.8rem;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.05);
  }
  background-color: gray;
}
