.Home {
  padding: 1.8rem 3rem;
  padding-right: 4rem;
  display: grid;
  // grid-template-columns: repeat(3, 1fr);
  grid-template-columns: minmax(25rem, 1fr) minmax(32rem, 1fr) minmax(
      34rem,
      1fr
    );

  row-gap: 2rem;
  column-gap: 2.5rem;
  // font-family: $tertiary-font;

  .box-heading {
    font-size: 1.9rem;
    font-weight: 500;
    margin: 0;
  }

  .span-2 {
    grid-column: span 2;
  }

  &_intro {
    grid-column: 1 / -1;
    padding: 1rem;
    padding-bottom: 0;

    .ContentBox_content {
      @include fc(0, 0);
      justify-content: space-between;
      gap: 2rem;
    }

    &_text {
      @include fc(0, 0);
      flex-direction: column;

      .greetings {
        margin: 0;
        font-size: 2.4rem;
        margin-bottom: 0.8rem;
      }
      .market-stat {
        font-size: 1.6rem;
        line-height: 2rem;
        color: $text-black;
        margin-bottom: 0.8rem;
      }
      .date {
        font-size: 1.8rem;
        color: $text-blue;
      }
    }
    &_ctas {
      @include fc;
      gap: 1rem;

      .cta {
        margin: 0;
        cursor: pointer;
        font-size: 1.6rem;
        line-height: 1;
        border: 1px solid $text-blue;
        border-radius: 5rem;
        padding: 1rem;
        min-width: 14rem;
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: $text-blue;
          color: $primary-white;
        }
      }
    }
  }

  &_allocation,
  &_performance,
  &_balance {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_chart {
      max-height: 25rem;
      // can be increased for smaller screens
    }
  }

  &_allocation {
    &_title {
      font-size: 2.2rem;
      text-align: center;
      font-weight: 400;
      margin: 0;
      margin-top: 2rem;
      font-family: $secondary-font;
    }
  }

  &_performance {
    &_range {
      @include fc(0, 1);
      margin-top: 1rem;
      gap: 2.5rem;

      &_item {
        font-size: 1.8rem;
        font-weight: 700;
        color: $secondary-gray;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        padding: 0;

        &.active {
          color: $text-blue;
        }
      }
    }
  }

  &_balance {
    &_chart {
      max-height: 30rem;
    }
    // .ContentBox_content {
    // }
  }

  &_holdings {
    &_table {
      margin-right: 1rem;

      .HoldingsTable_row {
        color: $primary-black;

        &.cash {
          @include fc(1, 0);
          justify-content: space-between;
          font-family: $tertiary-font;
          border-bottom: 2px solid $secondary-gray;
          padding-bottom: 1.5rem;
          font-size: 2.4rem;

          .left {
            @include fc(1, 0);
            gap: 2rem;

            svg {
              display: inline-block;
              width: 6rem;
              height: 6rem;
            }

            // .label {
            // }
          }
          .right {
            font-family: $secondary-font;
            font-weight: 500;
          }
        }
      }
      .HoldingsTable_row:last-child .Ticker-holding {
        border-bottom: none;
      }

      .HoldingsTable_viewAll {
        width: 100%;
        text-align: center;
        margin-top: 2rem;
        font-size: 1.6rem;
        color: $primary-gray;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
          color: $text-blue;
          text-decoration: underline;
          text-underline-offset: 0.2rem;
        }
      }
    }
  }

  &_watchlist {
    min-height: 30rem;
    height: max-content;

    .ContentBox_content {
      padding: 3rem;
    }

    // @include down-query(1320px) {
    //   grid-row: 3 / 4;
    //   grid-column: 2 / -1;
    //   height: 100%;
    // }
  }

  @include down-query(1320px) {
    grid-template-columns: repeat(2, minmax(20rem, 1fr));

    &_intro {
      &_ctas {
        flex-direction: column;
      }
    }

    &_performance {
      grid-column: 1 / -1;
      grid-row: 2 / 3;
    }

    &_watchlist {
      grid-column: 1 / -1;
    }
  }
}


.passiv {
  padding: 0px 40px;
}
.mt-5 {
  margin-top: 40px;
}
.mb-5{
  margin-bottom: 40px;
}
.passiv{

  ul {
    padding: 0;
  }
  .pros-heading {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .pros-heading h6 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
  .pros-body .ant-progress-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
    background-color: #d7d7d7;
    height: 20px;
    margin-top: 18px;
    border-radius: 0;
  }
  .pros-body .ant-progress-bg {
    height: 20px !important;
    border-radius: 0 !important;
    background: #91d9f8 !important;
  }
  .pros-body {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .card-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 10px;
  }
  button.ant-btn.ant-btn-default.btn-analyst {
    border-radius: 10px !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    color: #000 !important;
  }
  .analysts-menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .analysts-list-items-left h6 {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }
  .analysts-list-items-left p {
    color: #00BCD4;
  }
  .analysts-menu li {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .info-btn {
    position: absolute;
    right: 0;
    font-size: 25px;
    color: #a3a3a3;
  }
  .right-heading-bar {
    display: flex;
    gap: 10px;
    margin-right: 35px;
  }
  
  .header-ss {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .profile-body {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cc-body-right h6 {
    font-size: 20px;
    font-weight: 700;
    color: #69d2ff;
  }
  .linebar-hh {
    display: flex;
    align-items: end;
    gap: 8px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .linebar-hh h4 {
    margin: 0;
    font-weight: 600;
  }
  .profile-user-list {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 10px;
  }
  .profile-user-list ul {
    padding: 0;
    list-style: none;
    margin: 0;
  }
  .profile-user-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 3px dotted #ccc;
    margin-bottom: 10px;
  }
  .left-list-menu h6 {
    color: black;
    font-weight: 500;
    margin: 0;
    font-size: 16px;
  }
  .left-list-menu span {
    width: 100%;
    font-size: 14px;
    line-height: 1.5;
    color: #8f8f8f;
  }
  .profile-user-list li:last-child {
    margin-bottom: 0;
    border: none;
    padding-bottom: 0;
  }
  
  .chatMainOuter {
    height: calc(100vh - 244px);
    min-height: 380px;
    // box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    overflow: hidden;
    transform: translate(0, 0);
  }
  .chatMainOuter .chatMainInner .contactSide {
    // box-shadow: 0px 0px 4px 0px #00000026;
  }
  .detail .name {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
  }
  .chatMainOuter .chatMainInner .contactSide .contactList .contactItem .message .newMsg {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
    background-color: #09c939;
    font-size: 14px;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatHeader,
  .chatMainOuter .chatMainInner .chattingSide .chatFooter {
    background-color: #0AA9DD;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatHeader .clientPro .imgOuter {
    width: 40px;
    height: 40px;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatHeader .clientPro .imgOuter img {
    object-fit: cover;
    object-position: top center;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatHeader .clientPro .detail .status {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatHeader .clientPro .detail .status .online {
    color: #fff;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatHeader.online .clientPro .detail .status .online {
    display: flex !important;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatHeader.online .clientPro .detail .status .lastSeen {
    display: none;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatOuter .msgDate .date {
    white-space: nowrap;
    font-size: 14px;
    color: #121212;
    background-color: #fff;
    border-radius: 4px;
    padding: 4px 12px;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatOuter .msgMain .imgOuter {
    width: 36px;
    height: 36px;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatOuter .msgMain .imgOuter img {
    object-fit: cover;
    object-position: top center;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatOuter .msgMain .msg {
    border-radius: 16px;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatOuter .msgMain .msg:last-child {
    margin-bottom: 16px !important;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatOuter .msgMain .msg.txtMsg {
    border-radius: 0 16px 16px;
    max-width: 80%;
    padding: 10px;
    width: fit-content;
    background-color: #fff;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatOuter .msgMain .msg.txtMsg .txt {
    display: contents;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #121212;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatOuter .msgMain .msg.docMsg {
    border: none;
    max-width: 280px;
    border-radius: 0;
    border-radius: 16px;
    position: relative;
    padding: 10px 10px 20px 10px;
    background-color: #fff;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatOuter .msgMain .msg.docMsg .txt {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #121212;
    margin-top: 4px;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatOuter .msgMain .msg.docMsg .document {
    background-color: #f6f6f6;
    border-radius: 6px;
    padding: 6px;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatOuter .msgMain .msg.docMsg .documentName {
    font-size: 14px;
    font-weight: 600;
    color: #121212;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatOuter .msgMain .msg.docMsg .msgTime {
    position: absolute;
    bottom: 6px;
    right: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    color: #121212;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatFooter .inputContainer input {
    height: 50px;
    border-radius: 25px;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatFooter .inputContainer input::placeholder {
    color: #555;
  }
  .chatMainOuter .chatMainInner .chattingSide .chatFooter .sendBtn svg path {
    fill: #fff;
  }
  

ul {
  padding: 0;
}
.pros-headding {
  display: flex;
  align-items: center;
  gap: 12px;
}
.pros-headding h6 {
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
}
.pros-body .ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #d7d7d7;
  border-radius: 100px;
  height: 20px;
  margin-top: 18px;
  border-radius: 0;
}
.pros-body .ant-progress-bg {
  height: 20px !important;
  border-radius: 0px !important;
  background: #91d9f8!important;
}
.pros-body {
  display: flex;
  align-items: center;
  gap: 10px;
}
.card-headding {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 10px;
}
button.ant-btn.ant-btn-default.btn-analist {
  border-radius: 10px !important;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  color: #000 !important;
}
.annlysts-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.analysts-list-items-left h6 {
  font-size: 18px;
  font-weight: 500;
  margin: 0px;
}
.analysts-list-items-left p {
  color: #00BCD4;
}
.annlysts-menu li {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 10px 0px;
}
.picture-user img {
  width: 100%;
}
.info-btn {
  position: absolute;
  right: 0;
  font-size: 25px;
  color: #a3a3a3;
}
.right-headding-bar {
  display: flex;
  gap: 10px;
  margin-right: 35px;
}


.header-ss {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-body {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cc-body-right h6 {
  font-size: 20px;
  font-weight: 700;
  color: #69d2ff;
}
.cc-body-right h6 {
  font-size: 20px;
  font-weight: 700;
  color: #69d2ff;
}
.linebar-hh {
  display: flex;
  align-items: end;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.linebar-hh h4 {
  margin: 0;
  font-weight: 600;
}
.profile-user-list {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 10px;
}
.profile-user-list ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
.profile-user-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 3px dotted #ccc;
  margin-bottom: 10px;
}
.left-list-menu h6 {
  color: black;
  font-weight: 500;
  margin: 0;
  font-size: 16px;
}
.left-list-menu span {
  width: 100%;
  font-size: 14px;
  line-height: 1.5;
  color: #8f8f8f;
}
.profile-user-list li:last-child{
  margin-bottom: 0px;
  border: none;
  padding-bottom: 0px;
}


.chatMainOuter{
  height: calc(100vh - 244px);
  min-height: 380px;
  // box-shadow: 0 0 4px rgb(0 0 0 / 15%);
  border-radius: 12px;
  overflow: hidden;
  transform: translate(0, 0);
}
.chatMainOuter .chatMainInner .conatctSide {
  // box-shadow: 0px 0px 4px 0px #00000026;
}
.detail .name {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
}
.chatMainOuter .chatMainInner .conatctSide .contactList .contactItem .message .newMsg{
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  background-color: #09c939;
  font-size: 14px;
}
.chatMainOuter .chatMainInner .chatingSide .chatHeader,
.chatMainOuter .chatMainInner .chatingSide .chatFooter {
  background-color: #0AA9DD;
}
.chatMainOuter .chatMainInner .chatingSide .chatHeader .clientPro .imgOuter {
  width: 40px;
  height: 40px;
}
.chatMainOuter .chatMainInner .chatingSide .chatHeader .clientPro .imgOuter img {
  object-fit: cover;
  object-position: top center;
}
.chatMainOuter .chatMainInner .chatingSide .chatHeader .clientPro .detail .status {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}
.chatMainOuter .chatMainInner .chatingSide .chatHeader .clientPro .detail .status .online {
  color: #fff;
}
.chatMainOuter .chatMainInner .chatingSide .chatHeader.online .clientPro .detail .status .online{
  display: flex !important;
}
.chatMainOuter .chatMainInner .chatingSide .chatHeader.online .clientPro .detail .status .lastSeen {
  display: none;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgDate .date {
  white-space: nowrap;
  font-size: 14px;
  color: #121212;
  background-color: #fff;
  border-radius: 4px;
  padding: 4px 12px;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .imgOuter{
  width: 36px;
  height: 36px;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .imgOuter img{
  object-fit: cover;
  object-position: top center;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg {
  border-radius: 16px;
  /* border: 1px solid #C8C8C8; */
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg:last-child {
  margin-bottom: 16px !important;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg.txtMsg{
  border-radius: 0 16px 16px;
  max-width: 80%;
  padding: 10px;
  width: fit-content;
  background-color: #fff;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg.txtMsg .txt {
  display: contents;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #121212;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg.docMsg {
  border: none;
  max-width: 280px;
  border-radius: 0;
  border-radius: 16px;
  position: relative;
  padding: 10px 10px 20px 10px;
  background-color: #fff;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg.docMsg .txt{
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #121212;
  margin-top: 4px;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg.docMsg .document{
  background-color: #f6f6f6;
  border-radius: 6px;
  padding: 6px;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg.docMsg .documentName {
  font-size: 14px;
  font-weight: 600;
  color: #121212;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg.docMsg .docIcon {
  width: 24px;
  height: 24px;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msg.docMsg .msgTime{
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 10px;
  margin: 0 !important;
  width: 100%;
  text-align: end;
  color: #121212;
}

.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.send .msg.docMsg {
  background-color: #0AA9DD;
}

.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.send .msg.docMsg .docIcon svg path {
  fill: #fff;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.send .msg.docMsg .documentName, 
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.send .msg.docMsg .msgTime,
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.send .msgCol .msg.txtMsg .txt,
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.send .msg.docMsg .txt {
  color: #fff;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.send .imgCol {
  display: none !important;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.send .msgCol .msg{
  margin-left: auto;
  border-color: #0AA9DD;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.send .msg.docMsg .document {
  background-color: #ffffff17;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.send .msgCol .msg.txtMsg{
  background-color: #0AA9DD;
  border-radius: 16px 0 16px 16px;
  color: #fff;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain .msgTime{
  font-size: 12px;
  font-weight: 500;
}
.chatMainOuter .chatMainInner .chatingSide .chatFooter .footerBtnGroup {
  top: 0;
  bottom: 0;
  margin: auto;
  right: 4px;
}
.chatMainOuter .chatMainInner .chatingSide .chatFooter .footerBtnGroup .fileAtechLabel {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.chatMainOuter .chatMainInner .chatingSide .chatFooter .footerBtnGroup .btnMsgSend {
  width: 32px;
  height: 32px;
  padding: 8px;
  transition: ease-in-out .3s;
  background-color: #0AA9DD;
  z-index: 6;
}
.chatMainOuter .chatMainInner .chatingSide .chatFooter .footerBtnGroup .btnMsgSend img, .backBtn img {
  filter: brightness(100);
}
 
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.send .msgCol::after {
  content: "";
  clip-path: polygon(100% 0, 0 0, 0 100%);
  width: 10px;
  height: 10px;
  background-color: #0aa9dd;
  right: -10px;
  top: 0;
  color: #0aa9dd;
  position: absolute;
  z-index: 999;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.send .msgCol {
  position: relative;
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.resive .msgCol::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  left: -10px;
  top: 0;
  color: #ffffff;
  position: absolute;
  z-index: 999;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
}
.chatMainOuter .chatMainInner .chatingSide .chatOuter .msgMain.resive .msgCol {
  position: relative;
}
.chat-header-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
span.chat-card-out {
  background-color: #0aa9dd;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
}
button.ant-btn.ant-btn-default.btn-chat-card {
  background-color: #cfcfcf !important;
  border: none;
  padding: 10px;
  border-radius: 10px !important;
}
.chat-body-t {
  gap: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0aa9dd;
  color: #fff;
  margin: 15px 00px;
  padding: 10px;
}
.chat-body-l h6 {
  color: #fff;
  font-size: 20px;
  width: 80%;
}
.chat-body-r {
  max-width: 115px;
  width: 100%;
  text-align: right;
}
.chat-body-r p {
  font-size: 14px;
  margin: 0px;
  line-height: 18px;
  text-align: right;
  letter-spacing: normal;
}
.chat-body-r h6 {
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  margin: 0px;
}
.chat-body-footer {
  padding: 10px;
  text-align: center;
}
.chat-body-footer span {
  color: green;
  font-size: 30px;
  padding-bottom: 10px;
}
.chat-body-footer h6 {
  font-size: 18px;
  color: green;
}
.clientPro {
  justify-content: space-between;
}
.header-search span {
  color: #fff;
  font-size: 18px;
}




/* extra css */

.card-pay {
  max-width: 500px;
  height: 300px;
  background-color: #91d9f8;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  width: 100%;
}
.card-pay h2 {
  font-size: 40px;
  margin: 0px;
  line-height: 50px;
  color: #fff;
}
.card-pay h2 span{
  font-size: 20px;
  margin: 0px;
  line-height: 50px;
  color: #fff;
}
.card-logo img {
  max-width: 100px;
  filter: brightness(0.5);
  float: right;
}
.card-pay p {
  position: absolute;
  bottom: 20px;
  font-size: 24px;
  letter-spacing: 10px;
  color: #686868;
  font-weight: 600;
}
.account-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 10px 5px;
}
.account-list {
  margin-top: 20px;
}
.account-list li h4 {
  font-size: 18px;
  font-weight: 700;
  font-family: sans-serif;
  margin: 0px;
  line-height: 22px;
}
li.text-sky h4 {
  color: #91d9f8;
}
li.text-sucses h4{
  color: green;
}
.account-list li p {
  font-size: 18px;
  font-weight: 500;
  margin: 0px;
}
.account-list ul {
  padding: 0;
}
li.list-footer {
  justify-content: center;
}
li.list-footer span {
  font-size: 16px;
  font-weight: 500;
  color: #91d9f8;
}
.headding-acc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headding-acc h3 {
  font-size: 22px;
  font-weight: 600;
  margin: 0px;
}
.recent-activity li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  border-bottom: 1px solid #ccc;
}
.re-left-menu h6 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 0px;
}
.re-left-menu span, .re-right-menu span {
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 500;
}
.recent-activity {
  margin-top: 20px;
}
.re-right-menu h6 {
  font-size: 20px;
  font-weight: 800;
  text-align: right;
  color: red;
  margin-bottom: 5px;
  margin-top: 0px;
}
h6.text-sucses {
  color: #00ab00;
}
a.view-a-btn {
  font-size: 20px;
  font-weight: 500;
}
.analysts-outer {
  position: relative;
  width: 100%;
  height: 250px;
}

.analysts-outer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.analysts-outer h5 {
  position: absolute;
  bottom: 12px;
  left: 12px;
  right: 12px;
  color: #fff;
  font-weight: 600;
  text-shadow: 0 0 10px black;
}
.resources-card-p .ant-card-body {
  padding: 0px;
  border-radius: 15px;
  overflow: hidden;
}
.resources-card-body.card-home.card-body p {
  padding: 20px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
}
.analysts-outer {
  position: relative;
  width: 100%;
  height: 250px;
  padding: 20p;
}

.analysts-outer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.analysts-outer h5 {
  position: absolute;
  bottom: 12px;
  right: 12px;
  left: 12px;
  color: #fff;
  font-weight: 600;
  text-shadow: 0 0 10px black;
}

.resources-card-p .ant-card-body {
  padding: 0px;
  border-radius: 15px;
  overflow: hidden;
}

.resources-card-body.card-home.card-body p {
  padding: 20px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
}

.profile-user {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.fuund-header {
  display: flex;
  gap: 15px;
  padding: 20px;
  border-bottom: 1px solid #ccc;
}

.user-details h5 {
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
}

.user-details h6 {
  color: #a1a1a1;
  margin: 0px;
  font-size: 16px;
}

.user-details p {
  color: #69daff;
  font-size: 15px;
  font-weight: 500;
  margin: 0px;
}
.ant-card.fund-cards .ant-card-body {
  padding: 0px;
}
.fund-body-text {
  padding: 20px;
}

.pics-details {
  padding: 20px;
}

.pics-details p {
  color: #69daff;
  font-size: 15px;
  font-weight: 500; 
  margin: 0px;
}

.pics-details h6 {
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.picture-body-text p {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #ccc;
}
.card-headding h2 {
  font-size: 25px;
  font-weight: 600;
  font-family: sans-serif;
  margin-bottom: 0px;
  margin-top: 0px;
}
.pic-section span {
  font-size: 16px;
  color: #383838;
  font-weight: 400;
}
.add-user-select {
  display: flex;
  gap: 10px;
  position: relative;
  width: 100%;
  padding: 4px 11px;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  margin-bottom: 15px;
}
.add-user-select span {
  font-size: 24px;
  color: #343434;
}
.add-user-select input {
  border: none;
  box-shadow: none;
  outline: none;
}
.ant-card.cms-cards .ant-card-body {
  background-color: #fff;
  // box-shadow: 0px 0px 10px #ededed;
  border-radius: 15px;
  padding: 20px;
  position: relative;
}
.picture-cammunity {
  width: 50px;
  height: 50px;
  border: 2px solid #69daff;
  border-radius: 50%;
  margin-bottom: 10px;
}
.chart-lines {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 16px;
  font-weight: 500;
}
.rec-acc ul {
  padding: 0;
  margin: 0;
}
li.acc-outer-main {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
 
.acc-btns {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rec-acc {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}
.acc-left-outer p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}
.acc-right-outer p {
  margin: 0px;
}
.vill-btn a {
  width: 100%;
  font-size: 16px;
  text-align: right;
}

.vill-btn {
  display: flex;
  justify-content: end;
  align-items: center;
}
.crpto-s {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
h6.bg-green {
  background-color: #dbffdb;
  padding: 5px 10px;
  width: fit-content;
  border-radius: 5px;
}
h6.bg-dangar {
  background-color: #ffdbdb;
  padding: 5px 10px;
  width: fit-content;
  border-radius: 5px;
}
.crpto-s h6 {
  margin: 0;
  font-size: 16px;
}
.analysts-list-items-right h6 {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}
.news-card {
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 300px;
}
.news-card img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news-detail {
  position: absolute;
  z-index: 4;
  background-color: aliceblue;
  margin: 10px;
  padding: 10px;
  bottom: 10px;
  border-radius: 10px;
  left: 10px;
  right: 10px;
}
.news-detail a {
  color: #69d2ff !important;
  font-size: 16px;
  font-weight: 500;
}
.port-card {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 15px;
  background-color: #fff;
}
.port-bottam {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.port-headding h6 {
  font-size: 16px;
  margin-top: 15px;
  font-weight: 500;
}
.port-bottam a {
  color: #69d2ff !important;
  font-size: 16px;
  font-weight: 500;
}
}
 

