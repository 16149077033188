.ContentBox {
  border: 1px solid $border-gray;
  background: $primary-white;

  &_heading {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 2rem 0rem 1rem 2rem;
    border-bottom: 1px solid $border-gray;
  }

  &_content {
    padding: 2rem;
  }
}
