.Landing {
  background-color: $primary-white;
  min-height: 100vh;
  overflow: hidden;

  padding: 1.2rem;
  display: flex;
  gap: 5rem;

  &_left {
    flex: 1;

    @include down-query(900px) {
      display: none;
    }
  }

  &_right {
    width: 60%;

    @include down-query(1275px) {
      width: 50%;
    }
    @include down-query(900px) {
      width: 100%;
      margin: 0 20%;
    }

    @include down-query(670px) {
      margin: 0 10%;
    }

    &-logo {
      width: 9rem;
      height: 9rem;
      margin-bottom: 4rem;
    }

    &_step-1,
    &_step-2 {
      padding-top: 22vh;

      @include down-query(900px) {
        padding-top: 15vh;
      }
    }

    // todo: fix marginbottom issue for step 2
    &_step-2 {
      max-width: 38rem;
      // form .otp-input {
      // margin-bottom: 4rem;
      // }
    }

    &-form {
      margin-bottom: 10vh;

      // @include down-query(1120px) {
      //   margin-bottom: 12rem;
      // }

      &_heading {
        font-size: 2.2rem;
        font-weight: 400;
        color: $primary-black;
        margin-bottom: 4rem;
        line-height: 3rem;
      }

      &-verified-check {
        width: 4rem;
        margin-left: 0.5rem;
        margin-bottom: 3rem;
      }

      .otp-input {
        input {
          width: 4rem;
          margin: 0 0.9rem;
          font-size: 3.2rem;
          border: none;
          outline: none;
          border-bottom: 1px solid $primary-gray;
        }

        &:nth-child(odd) > input {
          border-bottom: 2px solid $primary-gray !important;
        }

        &-error {
          font-family: $secondary-font;
          color: $primary-red;
          font-size: 1.5rem;
          font-weight: 400;
          margin: 1.5rem 0 2rem 1rem;

          &.hidden {
            pointer-events: none;
            margin-bottom: 5rem;
          }
        }
      }

      .App-Input {
        font-family: $secondary-font;
        font-weight: 600;
        margin-bottom: 4rem;
        width: max-content;

        .left-default {
          cursor: pointer;
        }
      }

      button.btn {
        min-width: 14rem;
        background-color: $secondary-gray;
        margin-bottom: 3rem;

        &.active {
          background-color: $primary-blue;
          color: $primary-white;
        }
      }

      .form-footer {
        font-size: 1.4rem;
        color: $primary-gray;

        &_link {
          color: $primary-gray;
          font-weight: 500;
          text-decoration: underline;

          &:hover {
            color: $primary-blue;
          }

          &-btn {
            cursor: pointer;
          }
        }
      }
    }

    footer {
      color: $primary-gray;
      font-size: 0.95rem;
      line-height: 1.2;
      width: 90%;
    }
  }
}




//

// html {
//   @include down-query(600px) {
//     font-size: 7px;
//   }
// }
