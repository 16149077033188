.Market {
  padding: 1.8rem 3rem;
  padding-right: 4rem;
  display: grid;
  grid-template-columns: minmax(50rem, 1fr) minmax(25rem, 1fr);
  grid-gap: 2rem;
  user-select: none;

  .ContentBox {
    &_heading {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .box-heading {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0;
  }

  &_majorMarketBox {
    grid-column: 1 / -1;
    // height: max-content;

    .MajorMarketSection {
      display: grid;
      grid-template-columns: repeat(4, minmax(10rem, 1fr));
      column-gap: 8rem;
      row-gap: 2rem;

      &_item {
        &_header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1rem;

          &_left {
            font-size: 2rem;
            font-weight: 600;
          }

          &_right {
            display: flex;
            flex-direction: column;
            gap: 0.6rem;
            font-weight: 600;

            .change {
              @include fc;
              gap: 0.2rem;
              font-size: 1.4rem;

              svg {
                width: 1rem;
              }

              &.up {
                color: $primary-green;
              }
              &.down {
                color: $primary-red;
              }
            }
          }
        }
        &_body {
          &_chart {
            width: 12.5rem;
          }
        }
      }
    }
  }

  &_headlinesBox {
    position: relative;
    grid-row: 2 / 4;
    height: max-content;
    // user-select: none;

    .Headline {
      display: flex;
      gap: 2rem;
      padding: 1.5rem 1rem 1.5rem 2.4rem;

      &:first-child {
        padding-top: 0px;
      }

      &:not(:last-child) {
        border-bottom: 2px solid rgba($secondary-gray, 0.4);
      }

      &_img img {
        border-radius: 50%;
        width: 9rem;
        height: 9rem;
      }

      &_body {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;

        .title {
          font-size: 1.6rem;
          line-height: 1.4;

          @include t-e;
        }
        .date {
          margin-top: 0.5rem;
          font-size: 1.5rem;
          font-weight: 600;
          color: $primary-gray;
        }

        .source {
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: 1.8rem;
          color: $text-blue;
        }
      }
    }
  }

  &_topGainersBox {
    position: relative;
    // height: max-content;
  }

  &_trendingBox {
    position: relative;
    grid-column: 2 / -1;
  }

  &_browseBox {
    position: relative;
    grid-column: 1 / -1;

    &_header {
      @include fc(1, 0);
      padding-top: 0.8rem;
      padding-bottom: 0.4rem;
      justify-content: space-between;
      padding-right: 2.5rem;

      .options {
        @include fc;
        gap: 1.2rem;
        font-size: 1.5rem;
        font-weight: 400;
        color: $primary-gray;

        .option {
          cursor: pointer;
          &.active {
            color: $text-blue;
          }
        }
      }
    }

    .BrowseSection {
      padding-left: 0.5rem;
      padding-right: 2rem;

      &_header {
        .filters {
          display: flex;
          gap: 1.4rem;
          margin-bottom: 1.2rem;
        }

        .tags {
          display: flex;
          align-items: center;
          margin-bottom: 4rem;

          .slider {
            overflow-x: auto;
            width: 100%;

            // remove scrollbar
            &::-webkit-scrollbar {
              display: none;
            }

            &_one,
            &_two {
              width: max-content;
            }
            &_two {
              margin-top: 1rem;
            }

            .RadioChipInput {
              margin-right: 1.4rem;
            }
          }

          .next-btn {
            user-select: none;
            &--browse-right {
              margin: 0 2rem;
              // flex: 1;
              // align-self: center;
              position: unset;
              // transform: rotate(-90deg);
              // display: flex;
            }
          }
        }
      }

      &_body {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 1rem;
        column-gap: 2.5rem;
        max-height: 50rem;
        overflow-y: scroll;

        // remove scrollbar
        &::-webkit-scrollbar {
          display: none;
        }

        @include down-query(1300px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }

  .MarketCard {
    border: 2px solid $secondary-gray;
    border-radius: 0.8rem;
    padding-top: 1rem;
    overflow: hidden;

    &_header,
    &_body {
      padding: 0 1rem;
    }

    &_header {
      @include fc(1, 0);
      justify-content: space-between;

      .left {
        @include fc;
        font-size: 1.8rem;
        font-weight: 600;
        gap: 1rem;

        img {
          width: 5rem;
          height: 5rem;
          border: 1px solid $secondary-gray;
          border-radius: 50%;
          padding: 1rem;
        }
        .name {
          margin-right: 2rem;
        }
      }

      &_ticker {
        margin-right: 0.4rem;

        .Ticker_label {
          font-size: 1.4rem;
          background-color: $primary-blue;
          min-width: max-content;
        }
      }
    }

    &_body {
      margin-top: 1.5rem;
      margin-bottom: 1rem;

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.4rem;

      &_item {
        @include fc(0, 0);
        gap: 1.4rem;
        flex-direction: column;
        white-space: nowrap;

        // 3rd and 6th item
        &:nth-child(3),
        &:nth-child(6) {
          text-align: right;
        }

        .label {
          font-size: 1.7rem;
          font-weight: 500;
          font-family: $secondary-font;
          color: rgba($primary-gray, 0.8);
        }
        .value {
          font-size: 1.8rem;
          color: $primary-black;
        }
      }
    }

    &_footer {
      width: 100%;

      &_chart {
        width: 100%;
        height: 10rem;
        margin-bottom: -2px;
        opacity: 0.3;
      }
    }
  }

  .MarketTable {
    margin-bottom: 2rem;
    user-select: none;
    max-height: 34rem;
    overflow: hidden;

    &_row {
      @include fc(1, 0);
      padding: 1rem 0.5rem;
      border-bottom: 2px solid rgba($secondary-gray, 0.4);

      &_logo {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        margin-right: 2rem;
      }

      &_ticker {
        margin-bottom: 0;

        .Ticker_label {
          background-color: $primary-blue;
          font-size: 1.2rem;
          min-width: max-content;
          margin-right: 1.4rem;
        }
      }

      &_companyName {
        font-size: 1.6rem;
      }

      &_stats {
        flex: 1;
        @include fc(1, 0);
        justify-content: flex-end;

        &_chart {
          width: 12.5rem;
          max-height: 6.25rem;
          margin-left: 1.2rem;
          margin-right: 2rem;
        }

        &_change {
          font-size: 1.4rem;
          font-weight: 700;
          white-space: nowrap;

          svg {
            width: 1.5rem;
          }

          &.up {
            color: $primary-green;
          }
          &.down {
            color: $secondary-red;
          }
        }
      }
    }
  }

  .next-btn {
    position: absolute;
    bottom: 1rem;
    right: 50%;
    transform: translateX(50%);
    width: 3.2rem;
    height: 3.2rem;
    cursor: pointer;
  }

  @include down-query(1300px) {
    &_headlinesBox,
    &_topGainersBox,
    &_trendingBox {
      grid-column: 1 / -1;
    }
  }
}
