@import url("https://fonts.googleapis.com/css2?family=Faster+One&display=swap");

.ComingSoon {
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  position: relative;

  .topleft .logo {
    display: none;
    position: absolute;
    top: 2.5rem;
    left: 2.5rem;
    width: 8.5rem;
  }

  .topright {
    display: none;
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    width: 8.5rem;
    font-size: 2rem;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    h1 {
      font-family: $secondary-font;
      font-size: 8rem;
      margin: 0;
      margin-bottom: 2rem;
      white-space: nowrap;
    }

    .msg {
      font-size: 2rem;
      margin: 0;
      margin-top: 2rem;
    }

    .countdown {
      font-size: 5rem;
      margin: 0;
      margin-top: 8rem;
      font-family: "Faster One", Times, serif;
      white-space: nowrap;
    }
  }

  .bottomright {
    position: absolute;
    bottom: 2.5rem;
    right: 2.5rem;
    font-size: 1.5rem;
  }

  @include down-query(800px) {
    .topleft .logo {
      width: 6.5rem;
    }

    .topright {
      top: 3rem;
    }

    .middle {
      h1 {
        font-size: 6rem;
      }
      .countdown {
        font-size: 4rem;
      }
    }

    .bottomright {
      font-size: 1.3rem;
    }
  }

  @include down-query(500px) {
    zoom: 0.85;
    .topleft,
    .topright {
      zoom: 1.2;
    }
  }

  @include down-query(400px) {
    .middle {
      h1 {
        font-size: 5rem;
      }
      .countdown {
        font-size: 3rem;
      }
    }
  }
}
