.Ticker {
  margin-bottom: 1rem;

  @mixin label {
    background-color: rgba($secondary-blue, 0.9);
    color: $primary-white;
    padding: 0.7rem 1rem;
    border-radius: 1.2rem;
    font-size: 2rem;
    font-weight: 600;
    min-width: 7rem;
    text-align: center;
    font-family: $secondary-font !important;
  }

  &-watchlist {
    @include fc(1, 0);
    gap: 1.4rem;
  }

  &_label {
    @include label;
  }

  &_name,
  &_change {
    font-size: 1.6rem;
    color: $primary-gray;
  }

  &_change {
    flex: 1;
    text-align: right;

    &.up {
      color: $primary-green;
    }
    &.down {
      color: $primary-red;
    }
  }

  &_left {
    display: flex;

    &_img {
      display: inline-block;
      width: 6rem;
      height: 6rem;
      margin-right: 2rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    &_shares {
      font-size: 1.6rem;

      &_quantity {
        @include fc(1, 0);
        gap: 1rem;
        margin-bottom: 1rem;

        .label {
          @include label();
        }
        .quantity {
          font-size: 2rem;
          color: $primary-black;
          font-family: $secondary-font;
        }
      }
    }
  }

  &_center {
    font-size: 2rem;
    text-align: center;
    font-family: $secondary-font;

    &_price {
      padding-left: 2rem;
      svg {
        width: 1.2rem;
        margin: 0 0.5rem;
      }
    }
  }

  &_right {
    justify-self: flex-end;
    text-align: right;

    &_value {
      font-size: 2.4rem;
      font-weight: 500;
      font-family: $secondary-font;
      margin-bottom: 1.2rem;
    }

    &_change {
      font-size: 1.8rem;
      font-family: $secondary-font;
      color: $primary-gray;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -1.6rem;
        transform: translateY(-50%);
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
      }

      &.green::before {
        background-color: $primary-green;
      }
      &.red::before {
        background-color: $secondary-red;
      }
    }
  }
}

.Ticker-holding {
  display: grid;
  grid-template-columns: minmax(max-content, 1fr) 1fr 1fr;
  align-items: flex-end;
  height: max-content;

  margin-bottom: 0;
  padding-top: 1.2rem;
  padding-bottom: 1.5rem;

  color: $primary-black;
  border-bottom: 2px solid $secondary-gray;
}
