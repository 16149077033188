.RadioChipInput {
  display: inline-flex;
  border-radius: 1.2rem;
  color: $primary-black;

  input {
    display: none;
  }

  label {
    display: inline-block;
    font-size: 1.6rem;
    font-weight: 400;
    min-width: 7rem;
    text-align: center;
    user-select: none;
    transition: all 0.2s ease-in-out;
    padding: 1.4rem 1rem;
    cursor: pointer;
    white-space: nowrap;
  }

  &.type1 {
    border: 1px solid $primary-black;
  }

  &.type2 {
    background: $secondary-gray;
  }

  &.active {
    &.type1 {
      border-color: $text-blue;
      color: $text-blue;
    }
    &.type2 {
      background: $primary-blue;
    }
  }
}
