.FullScreenLoader {
  position: fixed;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

  .loader {
    text-align: center;
    img {
      width: 12rem;
    }

    p {
      margin-top: 4rem;
      font-size: 2.2rem;
      line-height: 1.5;
    }
  }
}
