.AppContainer {
  display: grid;
  grid-template-areas:
    "sidebar header"
    "sidebar content";
  grid-template-columns: max-content 1fr;

  #sidebar {
    grid-area: sidebar;
    width: 30rem;
    height: 100vh;
  }

  #miniSidebar {
    grid-area: sidebar;
    width: 8rem;
    height: 100vh;
  }

  #header {
    grid-area: header;
    height: 10rem;
  }

  #content {
    grid-area: content;
    height: calc(100vh - 10rem);
    background: $tertiary-blue;
    overflow-y: scroll;
  }

  // @include down-query(1000px) {
  //   grid-template-areas: "header" "content";

  //   #sidebar {
  //     display: none;
  //   }
  //   #header,
  //   #content {
  //     width: 100vw;
  //   }
  // }
}

@import "./Sidebar";
@import "./Header";
