.Sidebar {
  background-color: $primary-white;
  border-right: 1px solid $border-gray;
  padding: 0 3.5rem;

  @include fc(0, 0);
  flex-direction: column;

  &_header {
    @include fc(1, 0);
    padding-top: 2.5rem;
    margin-bottom: 6rem;

    .logo {
      user-select: none;
      padding-left: 1rem;

      img {
        width: 80%;
        margin: 0 auto;
      }
    }

    @include down-query(1350px) {
    }
  }

  .SidebarList {
    display: flex;
    flex-direction: column;

    .SidebarItem {
      @include fc(1, 0);
      padding: 1rem 1.5rem;
      margin-bottom: 1.5rem;
      // min-height: 48px;
      color: $primary-gray;
      font-size: 1.5rem;
      border-radius: 0.5rem;

      &:hover,
      &.active {
        box-shadow: 0 0 0 2px $primary-blue;
      }

      &.active {
        color: $primary-blue;
        // .text {
        // }
      }

      .leftIcon {
        margin-right: 2.4rem;

        svg {
          margin-top: 2px;
          width: 2.4rem;
          height: 2.4rem;
        }
      }

      .text {
        @include fc(1, 0);
        font-weight: 400;
        margin-top: 4px;
        height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
      }
    }
  }

  &_footer {
    @include fc;
    margin-top: auto;
    margin-bottom: 6rem;

    img {
      width: 6.5rem;
    }
  }
}

.MiniSidebar {
  background-color: $primary-white;
  border-right: 1px solid $border-gray;

  &_header {
    @include fc(1, 0);
    padding-top: 2.5rem;
    margin-bottom: 6rem;

    .logo {
      user-select: none;
      padding-left: 1rem;

      img {
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  .SidebarList {
    .SidebarItem {
      @include fc(1, 0);
      flex-direction: column;
      padding: 16px 0 14px;
      font-size: 1.2rem;
      color: $primary-gray;

      &:hover,
      &.active {
        color: $primary-blue;
      }

      &.active {
        color: $primary-blue;
        box-shadow: 2px 2px 3px rgba(0, 0, 0.1, 0.1);
      }

      .leftIcon {
        margin-bottom: 4px;

        svg {
          margin-top: 2px;
          width: 2.4rem;
          height: 2.4rem;
        }
      }

      .text {
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
